jQuery(
	function ($) {
		function show_all(e) {
			e.children('.dropdown-filter-checkbox')
				.removeClass('checked-false')
				.addClass('checked-true')
				.html('<i class="fas fa-check"></i>');

			e.find('.show-current .dropdown-filter-checkbox').each(
				function () {
					$(this)
						.removeClass('checked-true')
						.addClass('checked-false')
						.empty();
				}
			);
		}

		function show_current(e) {
			var checked = [];

			// Check or uncheck the filter
			if (e.children('.dropdown-filter-checkbox').hasClass('checked-false')) {
				e.children('.dropdown-filter-checkbox')
					.removeClass('checked-false')
					.addClass('checked-true')
					.html('<i class="fas fa-check"></i>');
			} else {
				e.children('.dropdown-filter-checkbox')
					.removeClass('checked-true')
					.addClass('checked-false')
					.empty();
			}

			// Uncheck the show all filter
			e.parent()
				.find('.show-all .dropdown-filter-checkbox')
				.removeClass('checked-true')
				.addClass('checked-false')
				.empty();

			// Push the check status into the checked array
			e.each(
				function () {
					checked.push($(this).hasClass('checked-true'));
				}
			);

			// Check the show all filter or not, it depends.
			if (!checked.includes(true)) {
				e.parent()
					.find('.show-all .dropdown-filter-checkbox')
					.addClass('checked-true')
					.removeClass('checked-false')
					.html('<i class="fas fa-check"></i>');
			}
		}

		$(document).on('keydown', function (event) {
			if (event.key == "Escape") {
				$('.dropdown-list')
					.addClass('d-none');
			}
		});


		$('body').on(
			'click',
			function (evt) {
				if ($(evt.target).is(
					`.dropdown-list,
					.dropdown-filter,
					.dropdown-filter-icon,
					.dropdown-filter-overlay,
					.dropdown-list-header,
					.dropdown-arrow-down,
					.dropdown-title,
					.dropdown-button,
					.dropdown-apply-filters,
					.dropdown-item-disabled`)) {
					return;
				}

				$('.dropdown-list')
					.addClass('d-none');

				$('.dropdown-filter')
					.addClass('dropdown-closed')
					.removeClass('dropdown-open')
					.removeClass('close');

				$('.dropdown-filter-icon').removeClass('d-none');
				$('.dropdown-close-icon').addClass('d-none');
			}
		);

		$('.dropdown-filter-overlay').on(
			'click',
			function () {
				var parent = $(this).parent();

				if (parent.hasClass('show-current')) {
					show_current(parent);
				}

				if (parent.hasClass('show-all')) {
					show_all(parent);
				}
			}
		)

		$('.dropdown-filter').on(
			'click',
			function () {
				var target = $(this).attr('data-target');
				var height = $(window).height();
				$('.dropdown-list').addClass('d-none');

				if ($(this).hasClass('dropdown-closed')) {
					$(this).removeClass('dropdown-closed')
						.addClass('dropdown-open')
						.addClass('close');

					$(this).closest('.dropdown-filter-icon').addClass('d-none');
					$(this).closest('.dropdown-close-icon').removeClass('d-none');
					$('.global-search-filter-list').addClass('d-none');
					$('.global-search-close-filter').removeClass('d-none');
					$(this).closest('.dropdown-list').addClass('d-none');

					if (!$(target).hasClass('no-auto-height')) {
						$(target).height(height);
					}

					$(target).removeClass('d-none');
				} else {
					$(this)
						.addClass('dropdown-closed')
						.removeClass('dropdown-open')
						.removeClass('close');

					$(this).closest('.dropdown-filter-icon').removeClass('d-none');
					$(this).closest('.dropdown-close-icon').addClass('d-none');
					$('.global-search-filter-list').removeClass('d-none');
					$('.global-search-close-filter').addClass('d-none');
					$(this).closest('.dropdown-list').addClass('d-none');
					$(target).addClass('d-none');
				}
			}
		);

		$('.dropdown-apply-filters').on(
			'click',
			function () {
				$('.dropdown-filter')
					.addClass('dropdown-closed')
					.removeClass('dropdown-open')
					.removeClass('close');

				$('.dropdown-filter-icon').removeClass('d-none');
				$('.dropdown-close-icon').addClass('d-none');
				$('.global-search-filter-list').removeClass('d-none');
				$('.global-search-close-filter').addClass('d-none');
				$('.dropdown-list').addClass('d-none');
				var target = $(this).attr('data-target');
				$(target).addClass('d-none');
			}
		);
	}
);