/**
 * Entry point of the child-theme
 * php version 7.4.3
 *
 * @category Theme
 * @package  KadabraitHelloChildTheme
 */

 (function($){ // phpcs:ignore
	'use strict';
	$( document ).ready(
		function() {
      $('.elementor-section').each(function(){
        if( $(this).hasClass('hero-light') ){
          $('#main-breadcrumbs li > *').css('color','#000000');
        }
      });
    }
	);

})( jQuery ); // phpcs:ignore
