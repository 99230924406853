/**
 * Entry point of the child-theme
 * php version 7.4.3
 *
 * @category Theme
 * @package  KadabraitHelloChildTheme
 */

 (function($){ // phpcs:ignore
	'use strict';
	$( document ).ready(
		function() {

			let _id              = '';
			let _name            = '';
			let _location        = 'all';
			let _team            = [];
			let _teams           = [];
			let _location_filter = [];
			let _job_filter      = [];
			let _fstatus         = false;
			let _titlecat        = '';
			let filter_team      = [];
			let currenturl       = window.location.href;
			if ($( '#teams-view' )) {

				loadPeopleResponse( _id, _name, _location );

				$( '.tv-cat-item' ).click(
					function(e) {
						_teams    = [];
						_location = 'all';
						_fstatus  = false;
						$( '#teams-view #tv-locations' ).val( 'all' );
						if ( ! $( this ).hasClass( 'active' )) {
							$( '#teams-view #tv-search input' ).val( '' );
							_id = $( this ).data( 'id' );
							if (_id != '9') {
								$( '#teams-view #tv-search' ).css( {display: 'flex'} );
								$( '#teams-view #tv-filters' ).css( {display: 'flex'} );
							} else {
								$( '#teams-view #tv-search' ).css( {display: 'none'} );
								$( '#teams-view #tv-filters' ).css( {display: 'none'} );
							}
							_titlecat = $( this ).text();
							$( '#tv-titlecat span.title' ).text( _titlecat );
							loadPeopleResponse( _id, _name, _location );
						}
						$( '.tv-cat-item' ).removeClass( 'active' );
						$( this ).addClass( 'active' );
						return false;
					}
				);

				$( '#teams-view #team-search input' ).keyup(
					function(event) {
						_name = $( this ).val();
						if ( _name != '' ) {
							_fstatus = true;
							loadPeopleResponse( _id, _name, _location );
						} else {
							_fstatus = false;
						}
						if ($( this ).val() == '') {
							_fstatus = false;
							loadPeopleResponse( _id, _name, _location );
						}
					}
				);
				$( '#teams-view #team-search img' ).click(
					function(e) {
						if ($( '#teams-view #team-search input' ).val() != '') {
							_name = $( '#teams-view #team-search input' ).val();
							loadPeopleResponse( _id, _name, _location );
							_name = '';
						} else {
							loadPeopleResponse( _id, _name, _location );
						}
						return false;
					}
				);

			}
			function loadPeopleResponse(id, name, location = '', team= 'all', filter=[]) {
				var filter_2 = filter.toString().split( "," );
				$( '#tv-content-response' ).html( "<div id='tv-infoload'><span>loading...</span></div>" );
				$.ajax(
					{
						method: 'GET',
						dataType: 'json',
						url:'/wp-json/midcap/v2/list-peoples-response',
						data: {id, name, location, team:filter_2},
						success: function (data, status, xhr) {
							if (data.items.length > 0) {
								$( '#tv-content-response' ).html( "" );
								showPeople( data.items, data.apollo, data.teams, data.location_filter, data.job_term );
							} else {
								$( '#tv-content-response' ).html( "<div id='tv-infoload'><span>Not data.</span></div>" );
							}
						},
						error: function (jqXhr, textStatus, errorMessage) {
							$( '#tv-content-responset' ).html( "<div id='tv-infoload'><span>Not data.</span></div>" );
						}
					}
				);
			}
			function showPeople(items, teams = [], location_filter = [], job_term = []) {
				if ($( '#tv-content-response' )) {
						presentationPeople( 'accordeon', items, teams, location_filter , job_term );
				}
			}

			function presentationPeople(type, items, apollo, teams = [], location_filter = [], job_term = [] ) {
				let _html         = '';
				let _teamshtml    = '';
				let _locationhtml = '';
				let _jobhtml      = '';

				switch (type) {
					case 'accordeon':
						if ( _job_filter.length == 0 ) {
							_job_filter = job_term;
							_jobhtml   += "<label class='dropdown-filter-item d-flex check-item a-center filter-button show-all' data-filter='*'>";
							_jobhtml   += "<span class='dropdown-filter-overlay d-xs-none d-sm-none d-lg-none d-xl-block'></span>";
							_jobhtml   += "<span class='dropdown-filter-overlay d-xs-block d-sm-block d-lg-block d-xl-none no-click'></span>";
							_jobhtml   += "<span class='dropdown-filter-checkbox checked-false'></span>";
							_jobhtml   += "<span class='global-search-filter-title f-bold'>Show all</span></label>";
							for (let iteam of job_term) {
								_jobhtml += "<label class='dropdown-filter-item check-item d-flex a-center filter-button show-current' data-filter='" + iteam.slug + "'>";
								_jobhtml += "<span class='dropdown-filter-overlay d-xs-none d-sm-none d-lg-none d-xl-block' data-filter='" + iteam.slug + "'>";
								_jobhtml += "</span>";
								_jobhtml += "<span class='dropdown-filter-overlay d-xs-block d-sm-block d-lg-block d-xl-none no-click' data-filter='" + iteam.slug + "'>";
								_jobhtml += "</span><span class='dropdown-filter-checkbox checked-false'>";
								_jobhtml += "</span><span class='dropdown-filter-title'>" + iteam.name + "</span></label>";
							}
							$( '.elementor-widget-teams_view_response .dropdown-list .teams_title' ).append( _jobhtml );
						}

						if ( _location_filter.length == 0 ) {
							_location_filter = location_filter;
							_teamshtml      += "<label class='dropdown-filter-item d-flex check-item a-center filter-button show-all' data-filter='*'>";
							_teamshtml      += "<span class='dropdown-filter-overlay d-xs-none d-sm-none d-lg-none d-xl-block'></span>";
							_teamshtml      += "<span class='dropdown-filter-overlay d-xs-block d-sm-block d-lg-block d-xl-none no-click'></span>";
							_teamshtml      += "<span class='dropdown-filter-checkbox checked-false'></span>";
							_teamshtml      += "<span class='global-search-filter-title f-bold'>Show all</span></label>";
							for (let itlocation of location_filter) {
								_teamshtml += "<label class='dropdown-filter-item check-item d-flex a-center filter-button show-current' data-filter='" + itlocation.slug + "'>";
								_teamshtml += "<span class='dropdown-filter-overlay d-xs-none d-sm-none d-lg-none d-xl-block' data-filter='" + itlocation.slug + "'>";
								_teamshtml += "</span>";
								_teamshtml += "<span class='dropdown-filter-overlay d-xs-block d-sm-block d-lg-block d-xl-none no-click' data-filter='" + itlocation.slug + "'>";
								_teamshtml += "</span><span class='dropdown-filter-checkbox checked-false'>";
								_teamshtml += "</span><span class='dropdown-filter-title'>" + itlocation.name + "</span></label>";
							}
							$( '.elementor-widget-teams_view_response .dropdown-list .teams_location' ).append( _teamshtml );
						}
						_html = "<div class='tv-accordion'>";
						let _subgroup = '';
						for (let item of items) {
							let _show        = ( item.items.length > 0 && _fstatus == true ) ? 'block' : 'none';
							let _active      = ( item.items.length > 0 && _fstatus == true ) ? 'active' : '';
							let _item_result = ( item.items.length === 0 ) ? 'enabled' : '';
								if (item.parent){
									if (_subgroup !== item.parent ){
										_subgroup  = item.parent;
										_html += "<div class = 'title-group' id='"+ item.slug +"'>"+ _subgroup  +"</div>";

									}

									let _childs      = "<ul class='tv-peoples'>";

								for (let child of item.items) {

									_childs += "<li class='tv-people-item' data-filter='" + child.data_filter_slug + "'";
									_childs += "data-location='" + child.data_filter_location + "'>";
									_childs += "<div class='item-image'>";
									_childs += "<a href='" + child.url + "'>";
									_childs += "<div class='image-people' style='background-image: url(" + child.photo_people + ")'></div>";
									_childs += "</a>";
									_childs += "</div>";
									_childs += "<div class='item-content'>";
									_childs += "<h3><a href='" + child.url + "'>" + child.display_name_people + "</a></h3>";
									_childs += "<h5>" + child.job_title_people + "</h5>";
									_childs += "<span>" + child.functional_titles + "</span>";
									_childs += "</div>";
									_childs += "</li>";
								}

								_childs += "</ul>";
								_html   += "<div class='tv-accordion-group  " + _active + "' id='" + item.team.toLowerCase().replace( /\s/g, '-' ) + "'>";
								_html   += "<a class='tv-accordion-title " + _item_result + "' href='#'>" + item.team + "</a>";
								_html   += "<div class='tv-accordion-body-part' style='display: " + _show + "'>" + _childs +"</div>";
								_html   += "</a>";
								_html   += "</div>";
								} else {
									let _childs      = "<ul class='tv-peoples'>";
									for (let child of item.items) {

										_childs += "<li class='tv-people-item' data-filter='" + child.data_filter_slug + "'";
										_childs += "data-location='" + child.data_filter_location + "'>";
										_childs += "<div class='item-image'>";
										_childs += "<a href='" + child.url + "'>";
										_childs += "<div class='image-people' style='background-image: url(" + child.photo_people + ")'></div>";
										_childs += "</a>";
										_childs += "</div>";
										_childs += "<div class='item-content'>";
										_childs += "<h3><a href='" + child.url + "'>" + child.display_name_people + "</a></h3>";
										_childs += "<h5>" + child.job_title_people + "</h5>";
										_childs += "<span>" + child.functional_titles + "</span>";
										_childs += "</div>";
										_childs += "</li>";
									}

									_childs += "</ul>";
									if ( item.team_id == 9 ){
										_html   += "<div class='tv-secction-group group-simple' id='" + item.team.toLowerCase().replace( /\s/g, '-' ) + "'>";
										_html   += "<a class='title-group" + _item_result + "' href='#'>Executive Leadership</a>";
										_html   += "<div class='tv-secction-body-part'>" + _childs + "</div>";
										_html   += "</a>";
									} else {
										_html   += "<div class='tv-secction-group group-simple' id='" + item.team.toLowerCase().replace( /\s/g, '-' ) + "'>";
										_html   += "<a class='title-group" + _item_result + "' href='#'>" + item.team + "</a>";
										_html   += "<div class='tv-secction-body-part'>" + _childs + "</div>";
										_html   += "</a>";
									}

									let _apollo = "<div class='tv-peoples'>";
									if ( item.team_id == 9 ){

										for (let items of apollo ) {
											for (let child of items.items ) {
											_apollo += "<li class='tv-people-item' data-filter='" + child.data_filter_slug + "'";
											_apollo += "data-location='" + child.data_filter_location + "'>";
											_apollo += "<div class='item-image'>";
											_apollo += "<a href='" + child.url + "'>";
											_apollo += "<div class='image-people' style='background-image: url(" + child.photo_people + ")'></div>";
											_apollo += "</a>";
											_apollo += "</div>";
											_apollo += "<div class='item-content'>";
											_apollo += "<h3><a href='" + child.url + "'>" + child.display_name_people + "</a></h3>";
											_apollo += "<h5>" + child.job_title_people + "</h5>";
											_apollo += "<span>" + child.functional_titles + "</span>";
											_apollo += "</div>";
											_apollo += "</li>";
											}
										}
									}
									_apollo += "</div>";
									_html += "<div class='title-group'>Apollo Management Advisors</div>";
									_html   += "<div class='tv-secction-body-part'>" + _apollo + "</div>";
								}
						}
						_html += "</div>";

					break;
				}
				$( '#tv-content-response' ).append( _html );
			}
			function show_all_filter(e) {
				e.children('.dropdown-filter-checkbox')
					.removeClass('checked-false')
					.addClass('checked-true')
					.html('<i class="fas fa-check"></i>');

				e.find('.show-current .dropdown-filter-checkbox').each(
					function () {
						$(this)
							.removeClass('checked-true')
							.addClass('checked-false')
							.empty();
					}
				);
			}

			function show_current_filter(e) {
				var checked = [];

				// Check or uncheck the filter
				if (e.children('.dropdown-filter-checkbox').hasClass('checked-false')) {
					e.children('.dropdown-filter-checkbox')
						.removeClass('checked-false')
						.addClass('checked-true')
						.html('<i class="fas fa-check"></i>');
				} else {
					e.children('.dropdown-filter-checkbox')
						.removeClass('checked-true')
						.addClass('checked-false')
						.empty();
				}

				// Uncheck the show all filter
				e.parent()
					.find('.show-all .dropdown-filter-checkbox')
					.removeClass('checked-true')
					.addClass('checked-false')
					.empty();

				// Push the check status into the checked array
				e.each(
					function () {
						checked.push($(this).hasClass('checked-true'));
					}
				);

				// Check the show all filter or not, it depends.
				if (!checked.includes(true)) {
					e.parent()
						.find('.show-all .dropdown-filter-checkbox')
						.addClass('checked-true')
						.removeClass('checked-false')
						.html('<i class="fas fa-check"></i>');
				}
			}
			$('body').on(
				'click',
				'#teams-view span.dropdown-filter-overlay',
				function() {
					var parent = $(this).parent();
					if (parent.hasClass('show-current')) {
						show_current_filter(parent);
					}

					if (parent.hasClass('show-all')) {
						show_all_filter(parent);
					}
			});
		}
	);
})( jQuery ); // phpcs:ignore

jQuery(
	function ($) {

		var filter_values_response = [];
		var filter_values_response_location = [];
		$( 'body' ).on(
			'click',
			'.dropdown-apply-filters',
			function () {
				$( '.teams_title .dropdown-filter-item' ).each(
					function () {
						data_filter = $( this ).attr( 'data-filter' );
						if (data_filter !== '*') {
							if ($( this ).children( '.dropdown-filter-checkbox' ).hasClass( 'checked-true' )) {
								filter_values_response.push( data_filter );
							} else {
								var index = filter_values_response.indexOf( data_filter );

								if (index !== -1) {
									filter_values_response.splice( index, 1 );
								}
							}
						}
						if ($( this ).hasClass( 'show-all' )) {
							filter_values_response = [];
						}
					}
				);
				$( '.teams_location .dropdown-filter-item' ).each(
					function () {
						data_filter = $( this ).attr( 'data-filter' );
						if (data_filter !== '*') {
							if ($( this ).children( '.dropdown-filter-checkbox' ).hasClass( 'checked-true' )) {
								filter_values_response_location.push( data_filter );
							} else {
								var index = filter_values_response_location.indexOf( data_filter );

								if (index !== -1) {
									filter_values_response_location.splice( index, 1 );
								}
							}
						}
						if ($( this ).hasClass( 'show-all' )) {
							filter_values_response_location = [];
						}
					}
				);


				$( '.tv-accordion-group' ).removeClass( 'active' );

				filter_people_response( filter_values_response, filter_values_response_location );
			},

		);

		function filter_people_response(filter_values_response, filter_values_response_location) {
			var card_filter = [];
			var location_filter = [];
			if (filter_values_response.length > 0 ) {
				jQuery( '.tv-accordion-group' ).removeClass( 'active' );
				jQuery( '.tv-accordion-body-part' ).css( 'display','none' );
				$( '#tv-content-response .tv-people-item' ).each(
					function () {
						var card_filter = $( this ).attr( 'data-filter' );
						if (card_filter !== undefined ) {
							card_filter = card_filter.split( ' ' );
						}
						var aux_array = $( card_filter ).filter( filter_values_response ).get();
						if (aux_array.length > 0) {
							$( this ).parent().parent().css( 'display','flex !important;' );
							$( this ).parent().parent().parent().find( 'a' ).removeClass( 'enabled' );
							$( this ).parent().parent().parent().addClass( 'active' );
							$( this ).attr( 'style', 'display:block;' );
						} else {
							$( this ).attr( 'style', 'display:none !important;' );
							$( this ).parent().parent().parent().find( 'a' ).addClass( 'enabled' );

						}
					}
				);
			}
			if (filter_values_response_location.length > 0 ) {
				jQuery( '.tv-accordion-group' ).removeClass( 'active' );
				jQuery( '.tv-accordion-body-part' ).css( 'display','none' );
				$( '#tv-content-response .tv-people-item' ).each(
					function () {
						var location_filter = $( this ).attr( 'data-location' );
						if (location_filter !== undefined ) {
							location_filter = location_filter.split( ' ' );
						}
						var aux_array2 = $( location_filter ).filter( filter_values_response_location ).get();
						if (aux_array2.length > 0) {
							$( this ).parent().parent().css( 'display','flex;' );
							$( this ).parent().parent().parent().find( 'a' ).removeClass( 'enabled' );
							$( this ).parent().parent().parent().addClass( 'active' );
							$( this ).attr( 'style', 'display:flex;' );
						} else {
							$( this ).attr( 'style', 'display:none;' );
							$( this ).parent().parent().parent().find( 'a' ).addClass( 'enabled' );

						}
					}
				);
			}
			if (filter_values_response.length > 0 && filter_values_response_location.length > 0) {
				jQuery( '.tv-accordion-group' ).removeClass( 'active' );
				jQuery( '.tv-accordion-body-part' ).css( 'display','none' );
				$( '#tv-content-response .tv-people-item' ).each(
					function () {
						card_filter   = jQuery( this ).attr( 'data-filter' ).split( ' ' );
						var aux_array = jQuery( card_filter ).filter( filter_values_response ).get();
						location_filter = jQuery(this).attr( 'data-location' ).split( ' ' );
						var aux_array2 = jQuery( location_filter ).filter( filter_values_response_location ).get();
						if (aux_array.length > 0 && aux_array2.length > 0) {
							$( this ).parent().parent().css( 'display','flex;' );
							$( this ).parent().parent().parent().find( 'a' ).removeClass( 'enabled' );
							$( this ).parent().parent().parent().addClass( 'active' );
							$( this ).attr( 'style', 'display:flex;' );
						} else {
							$( this ).attr( 'style', 'display:none;' );
							$( this ).parent().parent().parent().find( 'a' ).addClass( 'enabled' );

						}
					}
				);
			}

			if (filter_values_response.length === 0 && filter_values_response_location.length === 0) {
				$( '#tv-content-response .tv-people-item' ).each(
					function () {
						$( this ).parent().parent().css( 'display','none;' );
						$( this ).parent().parent().parent().find( 'a' ).removeClass( 'enabled' );
						$( this ).parent().parent().parent().removeClass( 'active' );
						$( this ).attr( 'style', 'display:flex;' );
					}
				);
			}
			jQuery('.tv-accordion-group').each(
				function () {
					if (jQuery(this).hasClass('active')){
						jQuery(this).find('a').removeClass('enabled');
					}
				}
			);
		}

		let searchParams2 = new URLSearchParams(window.location.search);
		let param2 = searchParams2.get('team');

		if( param2 ){
			const teamparm = param2.split("/", 2);
			var nparam2;
			if (teamparm[1]){
				nparam2 = teamparm[1];
			}else{
				nparam2 = teamparm[0];
			}
		}
		var width = $(window).width();

		if (width < 1025){
			setTimeout(
				function () {
					$('.group-simple').children().each(
						function (e) {
							let idgroup = $(this).attr('id');
							if (nparam2 == idgroup) {
								$(this).addClass('active');
								$('html, body').animate(
									{
										scrollTop: $(this).offset().top - 180
									},
									1000
								);
								return false;
							}
						}
					);
				},
				3000
			);
		}
	}
);
