var Xhr = (function () {
	function self() { }

	self.Request = function (params) {
		jQuery.ajax({
			type: params.type || 'GET',
			cache: params.cache || false,
			contentType: params.contentType || false,
			data: params.data || {},
			processData: params.processData || false,
			async: params.async || true,
			url: params.url || ajax_object.ajax_url,
			error: function (xhr, textStatus, errorThrown) {
				params.error(xhr, textStatus, errorThrown);
			},
			success: function (data, textStatus, xhr) {
				params.success(data, textStatus, xhr)
			}
		});
	};

	return self;

})(jQuery);