var filters = [];

function ajax_filter_cards(filter_values, current_page, method, parent_list = null, filter_list = null) {
	jQuery.ajax(
		{
			type: 'POST',
			dataType: 'json',
			url: ajax_object.ajax_url,
			data: {
				'action': 'transactions_filters_and_pager',
				'paged': current_page,
				'filters': filter_values
			},
			beforeSend: function () {
				console.log('getting transactions...');
			},
			success: function (res) {
				if (!filter_list) {
					if (parent_list) {
						if (parent_list.hasClass('t-industry-type-list')) {
							filter_list = '.p-business-solution-list';
						}

						if (parent_list.hasClass('p-business-solution-list')) {
							filter_list = '.t-industry-type-list';
						}
					}
				}
				if (res.solutions && res.industries) {
					filters = filters.concat(res.solutions);
					filters = filters.concat(res.industries);
				}

				if (filters) {
					jQuery(filter_list)
						.find('.dropdown-filter-item')
						.each(
							function () {
								var classes = jQuery(this).attr("class").split(/\s+/);
								var found = classes.some(r => filters.indexOf(r) >= 0);
								var show_all = classes.includes('show-all');
								if (!found && !show_all) {
									jQuery(this).addClass('dropdown-item-disabled');
								} else {
									jQuery(this).removeClass('dropdown-item-disabled');
								}
							}
						);
				} else {
					jQuery('.dropdown-filter-item').removeClass('dropdown-item-disabled');
				}
				filters = [];
				if (res.all !== res.total) {
					jQuery('.transactions-header')
						.children('h2')
						.html(res.total + ' Transactions');
				} else {
					jQuery('.transactions-header')
						.children('h2')
						.html('All Transactions <span class="transactions-counter">(' + res.all + ')</span>');
				}

				if (res.paged < res.max) {
					jQuery('#transactions-load-more-wrapper')
						.addClass('d-flex')
						.removeClass('d-none');
				} else {
					jQuery('#transactions-load-more-wrapper')
						.removeClass('d-flex')
						.addClass('d-none');
				}

				if (method === 'append') {
					jQuery('#transactions-grid').append(res.html);
				}

				if (method === 'html') {
					jQuery('#transactions-grid').html(res.html);
				}

			},
			error: function (xhr, status, error) {
				console.error(error);
			}
		}
	);
}

function add_remove_close() {
	if (jQuery('#filters-row-items').length === 0) {
		return;
	}

	if (jQuery('#filters-row-items').html().length === 1) {
		jQuery('#filters-row-close')
			.addClass('d-none')
			.removeClass('d-block');
	} else {
		jQuery('#filters-row-close')
			.removeClass('d-none')
			.addClass('d-block');
	}
}

function remove_checked_from_list(filter_values) {
	jQuery('.dropdown-filter-overlay').each(
		function () {
			if (!filter_values.includes(jQuery(this).data('filter'))) {
				if (jQuery(this).parent().hasClass('show-current')) {
					jQuery(this).nextAll('.dropdown-filter-checkbox')
						.removeClass('checked-true')
						.addClass('checked-false')
						.empty();
				}

				if (jQuery(this).parent().hasClass('show-all')) {
					jQuery(this).nextAll('.dropdown-filter-checkbox')
						.addClass('checked-true')
						.removeClass('checked-false')
						.html('<i class="fas fa-check"></i>');
				}
			}
		}
	);
}

function outside_filters(e, data_filter, filter_values) {
	var before = '<div class="filter-row-item d-flex ' + data_filter + '" data-filter="' + data_filter + '">';
	var close_icon = '<img src="' + ajax_object.theme_path + '/img/close-icon.svg">';
	var filter_title = '<div class="filter-row-item-title"> ' + e.nextAll('.dropdown-filter-title').html() + '</div>';
	var after = '</div>';

	if (e.parent().hasClass('show-current')) {
		if (e.nextAll('.dropdown-filter-checkbox').hasClass('checked-true')) {
			jQuery('#filters-row-items').append(
				before + close_icon + filter_title + after
			);
		} else {
			jQuery('.' + data_filter).remove();
		}
	} else {
		remove_checked_from_list();
		jQuery('.filter-row-item').remove();
		add_remove_close();
		filter_values = [];
		current_page = 1;
		ajax_filter_cards(filter_values, current_page, 'html', false);
	}

	add_remove_close();
}

jQuery(
	function ($) {
		var current_page = 1;
		var filter_values = [];
		var data_filter = null;
		var e = null;
		var filter_list = null;
		var parent_list = null;

		if (ajax_object.get_filter !== null) {
			$('.dropdown-filter-item').each(
				function () {
					if ($(this).attr('data-filter') === ajax_object.get_filter) {
						e = $(this).children('.dropdown-filter-overlay')[0];
						$(e).trigger('click');
					}
				}
			)

			filter_values.push(ajax_object.get_filter);
			data_filter = ajax_object.get_filter;
			ajax_filter_cards(filter_values, current_page, 'html');
			outside_filters($(e), data_filter, filter_values);

			if ($('#transactions-lm-button').length > 0) {
				$('#transactions-lm-button').attr('data-filters', filter_values);
			}
		}

		$('#form-import-transactions').on(
			'submit',
			function (e) {
				e.preventDefault();
				var form = new FormData($(this)[0])
				form.append('action', 'get_rows_from_file')

				$.ajax({
					url: ajax_object.ajax_url,
					type: 'POST',
					dataTyte: 'json',
					data: form,
					processData: false,
					contentType: false,
					enctype: 'multipart/form-data',
				}).done((res) => {
					var data = new FormData()
					data.append('action', 'import_transactions')

					res.forEach(
						(val, index) => {
							data.append('row', val)

							$.ajax({
								url: ajax_object.ajax_url,
								type: 'POST',
								dataTyte: 'json',
								data: data,
								processData: false,
								contentType: false,
							}).done((res) => {
								$('#transactions-importer-messages')
									.append(res + ' - Excel row: ' + (parseInt(index) + parseInt(1)))

								$('#transactions-importer-messages')
									.scrollTop(
										$('#transactions-importer-messages')
											.prop("scrollHeight")
									);
							}).catch((error) => {
								$('#transactions-importer-messages')
									.append('<br><span style="color:red">An error was ocurred with <strong>' + val[1] + ' - Excel row: ' + (parseInt(index) + parseInt(1)) + '</strong></span>')

								console.log(error);
							}).always(() => {
								$('#transactions-importer-messages')
									.scrollTop(
										$('#transactions-importer-messages')
											.prop("scrollHeight")
									);
							});
						}
					)
				});
			}
		);

		$('#transactions-lm-button').on(
			'click',
			function (e) {
				e.preventDefault();
				current_page++;
				var data_filters = $(this).attr('data-filters');
				ajax_filter_cards(data_filters, current_page, 'append', parent_list, filter_list);
			}
		);

		$('.transactions-dropdown').find('.dropdown-filter-overlay').on(
			'click',
			function () {
				if ($(this).hasClass('no-click')) {
					return;
				}

				parent_list = $(this).parent().parent().parent();
				current_page = 1;
				data_filter = $(this).attr('data-filter');

				if ($(this).nextAll('.dropdown-filter-checkbox').hasClass('checked-true')) {
					filter_values.push(data_filter);
				} else {
					var index = filter_values.indexOf(data_filter);

					if (index !== -1) {
						filter_values.splice(index, 1);
					}
				}

				if ($('#transactions-lm-button').length > 0) {
					$('#transactions-lm-button').attr('data-filters', filter_values);
				}

				if ($(this).parent().hasClass('show-current')) {
					if ($(this).closest('.dropdown-list').prev('.dropdown-filter').hasClass('outside')) {
						outside_filters(
							$(this),
							data_filter,
							filter_values
						);
					}
				}

				if ($(this).parent().hasClass('show-all')) {
					filter_values = [];
					$('#filters-row-items').empty();
					$('#filters-row-close').addClass('d-none');
				}

				ajax_filter_cards(filter_values, current_page, 'html', parent_list);
			}
		);

		$('#filter-row-close-button').on(
			'click',
			function () {
				$('.dropdown-filter-item').removeClass('dropdown-item-disabled');
				$('.filter-row-item').remove();
				add_remove_close();
				filter_values = [];
				remove_checked_from_list(filter_values);

				if ($('#transactions-lm-button').length > 0) {
					$('#transactions-lm-button').attr('data-filters', '');
				}

				ajax_filter_cards(filter_values, 1, 'html');
				window.history.pushState({}, document.title, window.location.href.split('?')[0]);
			}
		)

		$(document).on('keydown', function (event) {
			if (event.key == "Escape") {
				$('.transaction-card').removeClass('is-flipped');
			}
		});

		$('#transactions-grid').on(
			'click',
			'.transaction-card',
			function () {
				$('.transaction-card').not($(this)).removeClass('is-flipped');
				$(this).toggleClass('is-flipped');
			}
		)

		$('#transactions-slider').on(
			'click',
			'.transaction-card',
			function () {
				$('.transaction-card').not($(this)).removeClass('is-flipped');
				$(this).toggleClass('is-flipped');
			}
		)

		$('.dropdown-apply-filters').on(
			'click',
			function () {
				current_page = 1;
				var item = $(this).parent().prev().children().children('.dropdown-filter-item');

				$(item).each(
					function () {
						data_filter = $(this).attr('data-filter');

						if (data_filter !== '*') {
							if ($(this).children('.dropdown-filter-checkbox').hasClass('checked-true')) {
								filter_values.push(data_filter);
							} else {
								var index = filter_values.indexOf(data_filter);

								if (index !== -1) {
									filter_values.splice(index, 1);
								}
							}
						}
					}
				);

				if ($('#transactions-lm-button').length > 0) {
					$('#transactions-lm-button').attr('data-filters', filter_values);
				}

				ajax_filter_cards(filter_values, current_page, 'html');
			}
		);

		jQuery('#filters-row-items').on(
			'click',
			'.filter-row-item',
			function (e) {
				current_page = 1;
				var data_filter = $(this).attr('data-filter');
				var index = filter_values.indexOf(data_filter);

				if (index !== -1) {
					filter_values.splice(index, 1);
				}

				if (jQuery('#transactions-lm-button').length > 0) {
					jQuery('#transactions-lm-button').attr('data-filters', filter_values);
				}

				ajax_filter_cards(filter_values, current_page, 'html');
				remove_checked_from_list(filter_values);
				jQuery(e.target).parent().remove();
				add_remove_close();
				window.history.pushState({}, document.title, window.location.href.split('?')[0]);
			}
		);
	}
);
