jQuery(
	function ($) {
		$('#switch-names-button').on(
			'click',
			function (e) {
				$.ajax(
					{
						type: 'POST',
						dataType: 'html',
						url: ajax_object.ajax_url,
						data: {
							'action': 'switch_people_names'
						},
						beforeSend: function () {
							console.log('sending');
						},
						success: function (res) {
							$('#swtich-names-messages').append(res);
						},
						error: function (xhr, status, error) {
							console.log(error);
						}
					}
				)
			}
		)
	}
);