jQuery(
	function ($) {
		$('#form-import-people').on(
			'submit',
			function (e) {
				e.preventDefault();
				var formData = new FormData($("#form-import-people")[0])
				formData.append('action', 'get_rows_from_file')

				Xhr.Request({
					data: formData,
					success: function (data) {
						$('#people-importer-messages')
							.empty()
							.html('File loaded. Importing people:')

						var formData = new FormData()
						formData.append('action', 'import_people');

						data.forEach(
							(val, index) => {
								formData.append('row', val)
								Xhr.Request({
									data: formData,
									success: function (data) {
										$('#people-importer-messages')
											.append(data + ' - Excel row: ' + (parseInt(index) + parseInt(1)))

										$('#people-importer-messages')
											.scrollTop(
												$('#people-importer-messages')
													.prop("scrollHeight")
											);
									},
									error: function () {
										$('#people-importer-messages')
											.append('<br><span style="color:red">An error was ocurred with <strong>' + val[1] + ' - Excel row: ' + (parseInt(index) + parseInt(1)) + '</strong></span>')

										$('#people-importer-messages')
											.scrollTop(
												$('#people-importer-messages')
													.prop("scrollHeight")
											);
									}
								})
							}
						)
					}
				})
			}
		);
	}
);