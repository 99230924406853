var filter_values = [];
var filter_title_arr = [];

function filter_results(filter_values) {
	var results_filter = [];
	var results_count = 0;

	if (filter_values.length > 0) {
		jQuery('.global-search-result').each(
			function () {
				results_filter = jQuery(this).attr('data-filter').split(' ');
				var aux_array = jQuery(results_filter).filter(filter_values).get();

				if (aux_array.length > 0) {
					results_count++;

					jQuery(this)
						.addClass('d-flex')
						.removeClass('d-none');
				} else {
					jQuery(this)
						.removeClass('d-flex')
						.addClass('d-none');
				}
			}
		);
	} else {
		jQuery('.global-search-result').each(
			function () {
				results_count++;
				jQuery(this)
					.addClass('d-flex')
					.removeClass('d-none');
			}
		);
	}

	return results_count;
}

function bold_results(search, selector, element) {
	var split_results = [];
	var split_search = search.split(' ');

	jQuery(selector).each(
		function () {
			let result = jQuery(this).html().replace('(', '').replace(')', '').trim();
			split_results = result.split(' ');

			let lower_results = split_results.map(element => {
				return element.toLowerCase().replace(',', '');
			});

			let lower_search = split_search.map(element => {
				return element.toLowerCase();
			});

			var aux_array = jQuery(lower_results).filter(lower_search).get();

			if (aux_array.length > 0) {
				if (element == 'title') {
					jQuery(this).addClass('f-bold');
				}

				if (element == 'text') {
					var index = null;
					var _this = jQuery(this);

					lower_search.forEach(
						function (s) {
							index = lower_results.indexOf(s);
							split_results[index] = '<strong>' + split_results[index] + '</strong>';
							var join_results = split_results.join(' ');
							_this.html(join_results);
						}
					);
				}
			}
		}
	);
}

function apply_filters(e) {
	var data_filter = e.parent().attr('data-filter');
	var results_count = 0;
	var filter_title = e.nextAll('.dropdown-filter-title').html();

	if (e.parent().hasClass('show-current')) {
		if (e.nextAll('.dropdown-filter-checkbox').hasClass('checked-true')) {
			filter_values.push(data_filter);
			filter_title_arr.push(filter_title);
		} else {
			var index = filter_values.indexOf(data_filter);
			var index_title = filter_title_arr.indexOf(filter_title);

			if (index !== -1) {
				filter_values.splice(index, 1);
			}

			if (index_title !== -1) {
				filter_title_arr.splice(index_title, 1);
			}
		}
	}

	if (e.parent().hasClass('show-all')) {
		if (e.nextAll('.dropdown-filter-checkbox').hasClass('checked-true')) {
			filter_values = [];
			filter_title_arr = [];
		}
	}

	// results_count = filter_results(filter_values);

	// jQuery('.global-search-counter')
	// 	.html('1-' + results_count + ' of ' + results_count + ' results');

	if (e.closest('.dropdown-list').prev('.dropdown-filter').hasClass('inside')) {
		if (filter_title_arr.length > 0) {
			jQuery('.dropdown-title')
				.html(filter_title_arr.join('-'));
		} else {
			jQuery('.dropdown-title')
				.html('Show all');
		}
	}
}

function close_global_search() {
	jQuery('.global-search-wrapper, .global-search-body-overlay')
		.addClass('d-none');

	jQuery('.fa-search').removeClass('d-none');
	jQuery('.fa-search').next().remove();
	jQuery('.global-search-input').val('');
	jQuery('.global-search-predictive-wrapper, .global-search-see-all-wrapper, .global-search-see-all-header, .global-search-load-more-wrapper')
		.removeClass('d-flex')
		.addClass('d-none');

	jQuery('.global-search-predictive-wrapper')
		.empty();
	
	jQuery('.global-search-close-filter')
		.html('Filter list');
}

function ajax_filter_results(filter_values, current_page, method) {
	jQuery.ajax(
		{
			type: 'POST',
			dataType: 'json',
			url: ajax_object.ajax_url,
			data: {
				'action': 'global_search_filter_pager',
				'paged': current_page,
				'filters': filter_values
			},
			beforeSend: function () {
			},
			success: function (res) {
				var of = parseInt(res.current_page) * parseInt(res.per_page);

				if (of > res.total) {
					of = res.total;
				}

				jQuery('.global-search-counter')
					.html('1-' + of + ' of ' + res.total + ' results');

				if (current_page >= res.max) {
					jQuery('.global-search-load-more-wrapper')
						.removeClass('d-flex')
						.addClass('d-none');
				} else {
					jQuery('.global-search-load-more-wrapper')
						.addClass('d-flex')
						.removeClass('d-none');
				}

				if (method === 'html') {
					jQuery('.global-search-predictive-wrapper')
						.html(res.html)
						.promise()
						.done(function () {
							bold_results(
								res.search,
								'.global-search-result-title',
								'title'
							);

							bold_results(
								res.search,
								'.global-search-result-text',
								'text'
							);
						});
				}

				if (method === 'append') {
					jQuery('.global-search-predictive-wrapper')
						.append(res.html)
						.promise()
						.done(function () {
							bold_results(
								res.search,
								'.global-search-result-title',
								'title'
							);

							bold_results(
								res.search,
								'.global-search-result-text',
								'text'
							);
						});
				}

			},
			error: function (error) {
				console.error(error)
			}
		}
	);
}

jQuery(
	function ($) {
		var current_page = 1;
		var search = '';

		$('.fa-search').on(
			'click',
			function () {
				$('.global-search-wrapper, .global-search-body-overlay')
					.removeClass('d-none');

				$('.global-search-input')
					.focus();

				$(this).addClass('d-none');

				$('.global-search-close')
					.clone(true)
					.appendTo(
						$(this).parent()
					);
			}
		);

		$(document).on('keydown', function (event) {
			if (event.key == "Escape") {
				close_global_search();
			}
		});

		$('.global-search-close').on(
			'click',
			function () {
				current_page = 1;
				close_global_search();
			}
		);

		$('.global-search-input').on(
			'keyup',
			function () {
				search = $(this).val();
				current_page = 1;

				$('.global-search-see-all-header')
					.addClass('d-none')
					.removeClass('d-flex');

				if (search.length > 0) {
					$.ajax(
						{
							type: 'POST',
							dataType: 'json',
							url: ajax_object.ajax_url,
							data: {
								'action': 'predictive_search',
								'search': search,
							},
							beforeSend: function () {
							},
							success: function (res) {
								if (res.found > 0) {
									$('.global-search-see-all-wrapper')
										.removeClass('d-none')
										.addClass('d-flex');
								} else {
									$('.global-search-see-all-wrapper')
										.addClass('d-none')
										.removeClass('d-flex');
								}

								$('.global-search-predictive-wrapper')
									.removeClass('d-none')
									.addClass('d-flex')
									.html(res.html)
									.promise()
									.done(function () {
										bold_results(
											search,
											'.global-search-predictive-link',
											'title'
										);
									});

								$('.global-search-load-more-wrapper')
									.addClass('d-none')
									.removeClass('d-flex');

							},
							error: function (error) {
								console.error(error)
							}
						}
					);
				} else {
					$('.global-search-see-all-wrapper')
						.addClass('d-none')
						.removeClass('d-flex');

					$('.global-search-predictive-wrapper')
						.addClass('d-none')
						.removeClass('d-flex')
						.empty();
				}
			}
		);

		$('.global-search-see-all').on(
			'click',
			function () {
				$.ajax(
					{
						type: 'POST',
						dataType: 'json',
						url: ajax_object.ajax_url,
						data: {
							'action': 'see_all_results',
						},
						beforeSend: function () {
						},
						success: function (res) {
							$('.global-search-predictive-wrapper')
								.html(res.html)
								.promise()
								.done(
									function () {
										bold_results(
											search,
											'.global-search-result-title',
											'title'
										);

										bold_results(
											search,
											'.global-search-result-text',
											'text'
										);
									}
								);

							$('.global-search-see-all-header')
								.removeClass('d-none')
								.addClass('d-flex');

							var of = 10;

							if (res.found < 10) {
								of = res.found;
							}

							$('.global-search-counter')
								.html('1-' + of + ' of ' + res.found + ' results');

							$('.global-search-see-all-wrapper')
								.addClass('d-none')
								.removeClass('d-flex');

							if (res.found > 10) {
								$('.global-search-load-more-wrapper')
									.removeClass('d-none')
									.addClass('d-flex');
							}
						},
						error: function (error) {
							console.error(error)
						}
					}
				);
			}
		);

		$('#global-search-lm-button').on(
			'click',
			function (e) {
				e.preventDefault();
				current_page++;
				var filter_values = $(this).attr('data-filters');
				ajax_filter_results(filter_values, current_page, 'append');
			}
		);

		$('.global-search-dropdown').find('.dropdown-filter-overlay').on(
			'click',
			function () {
				if ($(this).hasClass('no-click')) {
					return;
				}

				current_page = 1;
				data_filter = $(this).attr('data-filter');

				if ($(this).nextAll('.dropdown-filter-checkbox').hasClass('checked-true')) {
					filter_values.push(data_filter);
				} else {
					var index = filter_values.indexOf(data_filter);

					if (index !== -1) {
						filter_values.splice(index, 1);
					}
				}

				if ($('#global-search-lm-button').length > 0) {
					$('#global-search-lm-button').attr('data-filters', filter_values);
				}

				if ($(this).parent().hasClass('show-all')) {
					filter_values = [];
				}

				ajax_filter_results(filter_values, current_page, 'html');
			}
		);

		$('.global-search-apply-filters').on(
			'click',
			function () {
				var dropdown_list = $(this).closest('.dropdown-list');

				$(dropdown_list).find('.dropdown-filter-overlay').each(
					function () {
						apply_filters($(this));
					}
				)
			}
		)

		$('.global-search-body-overlay').on(
			'click',
			function () {
				close_global_search();
			}
		);
	}
);