jQuery(
	function ($) {
		$('.careers-groups-item').on(
			'click',
			function () {
				var target = $(this).attr('data-target');
				var child = $(this).attr('data-child');
				var has_child = $(this).attr('data-has-child');
				var active = $(this).hasClass('active');
				var child_active = $(this).hasClass('child-active');

				if (active && has_child === 'true') {
					$(this).removeClass('active');
					$(child).addClass('d-none');
					$(this).find('.careers-icon.close').addClass('d-none');
					$(this).find('.careers-icon.plus').removeClass('d-none');
					$(this).css('border-bottom', '1px solid #67dbb4');
					return;
				}

				if (has_child === 'true') {
					$(this).css('border-bottom', 'none');

					var childrens = $(this).next('.careers-groups-childs-wrapper')
						.children()
						.not('.d-none')
						.size();

					var current = 1;

					$(this).next('.careers-groups-childs-wrapper')
						.children()
						.each(
							function () {
								if(!$(this).hasClass('d-none')) {
									if (childrens === current) {
										$(this).css('border-bottom', '1px solid #67dbb4');
									}

									current ++;
								}
							}
						)
				}

				if (child_active) {
					$(this).removeClass('child-active');
					$(this).find('.careers-icon.close').addClass('d-none');
					$(this).find('.careers-icon.plus').removeClass('d-none');
					$(child).addClass('d-none');
					$('.careers-group-child-desc').addClass('d-none');
					return;
				}

				$('.careers-groups-item').removeClass('active');
				$('.careers-group-child').removeClass('active');
				$(this).addClass('active');
				$('.careers-group-child-desc, .careers-group-item-desc, .careers-groups-childs-wrapper')
					.addClass('d-none');
				$(target).removeClass('d-none');
				$(child).removeClass('d-none');
				$('.careers-icon.plus').removeClass('d-none')
				$('.careers-icon.close').addClass('d-none')
				$(this).find('.careers-icon.plus').addClass('d-none');
				$(this).find('.careers-icon.close').removeClass('d-none');
			}
		);

		$('.careers-group-child').on(
			'click',
			function () {
				var target = $(this).attr('data-target');
				$('.careers-group-child').removeClass('active');
				$(this).addClass('active');
				$(this).parent().prev().addClass('child-active');
				$('.careers-groups-item').removeClass('active');
				$('.careers-group-child-desc').addClass('d-none');
				$(target).removeClass('d-none');
			}
		);
	}
);