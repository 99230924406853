/**
 * Javascript to handle the timeline graphic
 */
// jshint esversion: 11
/* globals timeline_data */

; // jshint ignore:line
(function($) {

    const debounce = (func, delay) => {
        delay = delay || 200;
        let debounceTimer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    var timeline = {
        canvas: document.getElementById('chart-timeline'),
        yAxisMin: -1.5,
        lineChart: false,
        hoverYear: false,

        animation: function(canvas, data, totalDuration) {
            const delayBetweenPoints = totalDuration / data.length;
            const previousY = (ctx) => ctx.index === 0 ? ctx.chart.getDatasetMeta(ctx.datasetIndex).data[0].getProps(['y'], true).y : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
            const scroller = $(canvas).closest('.chart--content-wrap').get(0);
            var milestones = $(scroller).find('.chart--axis-milestone').map( (i, el) => el).toArray();

            var scrollDelta = false;
            function initProgressAnimation() {
                scrollDelta = scroller.scrollWidth - scroller.clientWidth;
            }

            function revealMilestones(offset) {
                // minimize iterations by using while + shift() and
                // that the hightlights array is effectively in ascending order of offsetLeft
                while (milestones.length && milestones[0].offsetLeft < offset) {
                    el = milestones.shift();
                    $(el).children().addClass('reveal');
                }
            }

            const animation = {
                x: {
                    type: 'number',
                    easing: 'linear',
                    duration: delayBetweenPoints,
                    from: NaN, // the point is initially skipped
                    delay(ctx) {
                        if (ctx.type !== 'data' || ctx.xStarted) {
                            return 0;
                        }
                        ctx.xStarted = true;
                        return ctx.index * delayBetweenPoints;
                    }
                },
                y: {
                    type: 'number',
                    easing: 'linear',
                    duration: delayBetweenPoints,
                    from: previousY,
                    delay(ctx) {
                        if (ctx.type !== 'data' || ctx.yStarted) {
                            return 0;
                        }
                        ctx.yStarted = true;
                        return ctx.index * delayBetweenPoints;
                    }
                },
                onProgress: function(ctx) {
                    if (ctx.initial) {
                        if (scrollDelta === false) {
                            initProgressAnimation();
                        }
                        let progress = (ctx.currentStep / ctx.numSteps) * scroller.scrollWidth;
                        revealMilestones(progress);

                        if (scrollDelta > 0) {
                            let scrollHead = Math.max(0, progress - scroller.clientWidth);
                            if (scrollHead != scroller.scrollLeft) {
                                scroller.scrollTo({left: scrollHead, behavior: 'smooth'});
                            }
                        }
                    }
                },
                onComplete: function(ctx) {
                    if (ctx.initial) {
                        if (scrollDelta > 0) {
                            scroller.scrollTo({left: (scroller.scrollWidth - scroller.clientWidth), behavior: 'smooth'});
                        } else {
                            $(canvas).closest('.chart--container').find('.chart--has-rollover').first().addClass('open');
                        }
                        revealMilestones(scroller.scrollWidth);
                        scrollDelta = false;
                    }
                },
            };
            return animation;
        },

        init: function(canvas, labels, values) {
            // Sanity check
            if (!canvas) {
                return;
            }

            const data = {
                labels: labels,
                datasets: [{
                    data: values,
                    fill: {
                      target: 'start',
                    },
                    borderColor: '#46d3a4',
                    borderWidth: 2,
                    pointRadius: 0,
                    segment: {
                        backgroundColor: ctx => (ctx.p1.raw.x > timeline.hoverYear ? '#395e69' : '#1E6A5E'),
                    }
                }]
              };

            timeline.lineChart = new Chart(canvas, {
                type: 'line',
                data: data,
                plugins: [ChartDeferred],
                options: {
                    animation: timeline.animation(canvas, values, 1500),
                    layout: {
                        padding: {
                            left: 22,
                            right: 22,
                            top: 120,
                        }
                    },
                    plugins: {
                        deferred: {
                            yOffset: '35%',
                        },
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        x: {
                            display: false,
                            grid: {
                                display: true,
                            },
                            type: 'linear',
                            min: values[0].x,
                            max: values.slice(-1)[0].x,
                        },
                        y: {
                            display: false,
                            grid: {
                                display: false,
                            },
                            min: timeline.yAxisMin,
                        }
                    }
                }
            });

            const chartContainer = $(canvas).closest('.chart--container');

            // slide controls (for mobile, buttons to scroll the chart left and right)
            const slideControls = chartContainer.find('.chart--slide-controls button');
            const scroller = chartContainer.find('.chart--content-wrap').get(0);

            slideControls.on('click', function(){
                const index = $(this).index();
                var left;

                switch (index) {
                    case 0: // left
                        left = 0;
                        break;

                    case 1: // centre
                        left = (scroller.scrollWidth - scroller.clientWidth) / 2;
                        break;

                    case 2: // right
                        left = scroller.scrollWidth - scroller.clientWidth;
                        break;
                }

                $(scroller).animate({ 'scrollLeft': left }, 1200, 'easeInOutCubic');
                $(this).addClass('active').siblings().removeClass('active');
            });

            $(scroller).on('scroll', debounce(timeline.scrollsnap, 300));

            timeline.showSlideControls(chartContainer);

            $(window).on('resize orientationchange', debounce(timeline.showSlideControls));

            if (screen && screen.orientation) {
                screen.orientation.addEventListener('change', debounce(timeline.showSlideControls));
            }

            chartContainer.find('.chart--has-rollover a').on('mouseover click', timeline.showHighlight);
        },
        scrollsnap: function() {
            const scroller = this;
            const chartContainer = $(this).closest('.chart--container');
            const slideControlsContainer = chartContainer.find('.chart--slide-controls');
            const slideControls = slideControlsContainer.find('button:not(".not-required")');

            let track = scroller.scrollWidth - scroller.clientWidth;
            let head = scroller.scrollLeft;
            let snapPoints = [0];
            if (slideControls.length > 2) {
                snapPoints.push(track/2);
            }
            snapPoints.push(track);
            let nearest = snapPoints.reduce((previous, current, i) => (Math.abs(current - head) < previous.distance ? {index: i, distance: Math.abs(current - head)} : previous ), {index: -1, distance: track});

            let sectionDistance = track / (slideControls.length - 1);
            let duration = Math.floor(1200 * nearest.distance / sectionDistance);
            $(scroller).animate({ 'scrollLeft': snapPoints[nearest.index] }, duration, 'easeInOutCubic');
            slideControls.removeClass('active').eq(nearest.index).addClass('active');
        },

        showHighlight: function(e) {
            self = $(this);

            // Add open to parent class to show the highlight, remove it from siblings to ensure only one highlight visible at a time.
            self.closest('li').addClass('open').siblings('.open').removeClass('open');

            // Animate the fill colour below the chart so that the colour to the left of the hovered axis value (year) appears a different colour
            // by changing the timeline.hoverYear and triggering a chart update, we get ChartJS to recalculate the fill colour under each segment
            // of the chart.
            const year = 1 * (self.text().match(/^\d+/)[0] ?? 0);
            timeline.hoverYear = year;
            timeline.lineChart.update('none');
        },
        showSlideControls: function(chartContainer) {
            chartContainer = (chartContainer && chartContainer.find) ? chartContainer : $(timeline.canvas).closest('.chart--container');
            const slideControlsContainer = chartContainer.find('.chart--slide-controls');
            const slideControls = slideControlsContainer.find('button');
            const scroller = chartContainer.find('.chart--content-wrap').get(0);

            if (scroller.scrollWidth > scroller.clientWidth) {
                slideControlsContainer.removeClass('not-required');

                const numButtons = Math.ceil(scroller.scrollWidth / scroller.clientWidth);
                slideControls.eq(1).toggleClass('not-required', (numButtons < 3));
            } else {
                slideControlsContainer.addClass('not-required');
            }

        },
    };

    var handler = function() {
        if (timeline.canvas && (typeof timeline_data !== 'undefined') && timeline_data.length) {
            timeline.init(timeline.canvas, null, timeline_data);
        }
    };

    $(handler);

})(jQuery);
